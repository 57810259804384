import { CircularProgress, Grid, List, Paper, Typography, useTheme } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParam } from 'react-use';

import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { navigate } from 'gatsby';
import Tree from '../../components/Tree';
import TreeBreadcrumbs from '../../components/TreeBreadcrumbs';
import ItemProgramaTrabajo from '../../components/templates/ItemProgramaTrabajo';
import { useGlobalStyles } from '../../utils/styles';
import { comites } from '../../utils/tu-instituto/comites';
import { IComite } from '../../utils/tu-instituto/interfaces';

const Comite = () => {
  const theme = useTheme();
  const comiteParam = useSearchParam('comite');
  const [loading, setLoading] = useState(true);
  const [comite, setcomite] = useState<Partial<IComite>>();
  const [programaTrabajo, setProgramaTrabajo] = useState<string>('');
  const globalClasses = useGlobalStyles();

  const [breadcrumbs, setBreadcrumbs] = useState<String[]>([]);

  const getData = () => {
    if (!comiteParam) return navigate('/tu-instituto/organos-colegiados');

    setLoading(true);
    const comiteSearch = comites.find(comite => comite.abreviatura === comiteParam);
    if (!comiteSearch) return navigate('/tu-instituto/organos-colegiados');

    setcomite(comiteSearch);
    setLoading(false);
  }

  const getTreeBreadcrumbs = (data: String[]) => {
    setBreadcrumbs(data);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <SEO title={loading ? 'Comité' : comite?.name || 'Comité'} />

      <div className={globalClasses.container}>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={120} color='primary' />
            <Typography style={{ marginTop: theme.spacing(2) }}>Buscando información, por favor espere...</Typography>
          </div>
        ) : null}

        {!loading ? (
          <>
            <Header title={comite?.name} />

            <div className={globalClasses.content}>
              <Grid container spacing={3}>
                {comite?.objetivos?.length ? (
                  <Grid item xs={12}>
                    <Typography variant='h2'>Objetivo(s)</Typography>
                    <ul>
                      {comite.objetivos.map((objetivo, index) => (
                        <li key={index}>
                          <Typography>{objetivo}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                ) : null}

                {comite?.funciones?.length ? (
                  <Grid item xs={12}>
                    <Typography variant='h2'>Funciones</Typography>
                    {comite.funciones.map((funcion, index) => (
                      <Fragment key={index}>
                        <Typography variant='h5' component='p' style={{ marginTop: theme.spacing(2) }}>{funcion.title}</Typography>
                        <ul>
                          {funcion.actividades.map((actividad, index) => (
                            <li key={index}>
                              <Typography>{actividad}</Typography>
                            </li>
                          ))}
                        </ul>
                      </Fragment>
                    ))}
                  </Grid>
                ) : null}

                {comite?.estructuras?.length ? (
                  <Grid item xs={12}>
                    <Typography variant='h2'>Estructura</Typography>
                    <ul>
                      {comite.estructuras.map((estructuras, index) => (
                        <li key={index}>
                          <Typography>{estructuras}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                ) : null}

                {comite?.marcoJuridico ? (
                  <Grid item xs={12}>
                    <Typography variant='h2'>Marco Juridico</Typography>
                    <Typography style={{ marginTop: theme.spacing(2) }}>{comite?.marcoJuridico}</Typography>
                  </Grid>
                ) : null}

                {comite?.miembros?.children.length ? (
                  <Grid item xs={12}>
                    <Typography variant='h2'>Miembros</Typography>
                    {
                      comite.miembros.mostrar ? (
                        <Grid container>
                      <img 
                      alt={comite.miembros.title} 
                      style={{ display: "inline" }}
                      src={comite.miembros.foto}
                      width={'100%'}
                    />
                    </Grid>
                      ): null
                    }
                    
                    {comite.miembros.children.map((categoria, index) => (
                      <Fragment key={index}>
                        <Typography variant='h4' component='p' style={{ marginTop: theme.spacing(2) }}>{categoria.title}</Typography>
                        <Grid container>

                          {categoria.miembros.map((miembros, key) => (
                            <Fragment key={key}>
                              <Grid item xs={12} sm={6} md={4}>
                                <ul>
                                <Typography variant='h6' component='p' style={{ marginTop: theme.spacing(2) }}>{miembros.description}</Typography>
                                <Typography>{miembros.name}</Typography>
                                </ul>
                              </Grid>
                            </Fragment>
                          ))}
                          </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                ) : null}

                {comite?.ubicacion ? (
                  <Grid item xs={12}>
                    <Typography variant='h2'>Ubicación</Typography>
                    <Typography style={{ marginTop: theme.spacing(2) }}>{comite?.ubicacion}</Typography>
                  </Grid>
                ) : null}

                {comite?.contacto?.length ? (
                  <Grid item xs={12}>
                    <Typography variant='h2'>Información de contacto</Typography>
                    <ul>
                      {comite.contacto.map((contacto, index) => (
                        <li key={index}>
                          <Typography>{`${contacto.name}: ${contacto.description}`}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Typography variant='h2'>Programa de trabajo</Typography>

                  {
                    comite?.programaTrabajo?.length ? (
                        <Paper style={{ marginTop: theme.spacing(2) }}>
                            <List disablePadding>
                            {
                                comite.programaTrabajo.map( (collapse, index) => (

                                      <ItemProgramaTrabajo
                                        key={index}
                                        text={collapse.title}
                                        programaTrabajo={programaTrabajo}
                                        setProgramaTrabajo={setProgramaTrabajo}
                                      >
                                          <TreeBreadcrumbs 
                                            array={breadcrumbs}
                                            setTreeBreadcrumbs={getTreeBreadcrumbs}
                                            //initialArray={'olachoto'}
                                          />
                                          <Tree 
                                              initialArray={collapse.list}
                                              activeBreadcrumbs={true}
                                              breadcrumbs={breadcrumbs}
                                              setBreadcrumbs={setBreadcrumbs}
                                          />
                                      </ItemProgramaTrabajo>
                                    
                                 ) )
                              }
                            </List>
                        </Paper>
                    ): null
                  }

                </Grid>
              </Grid>
            </div>
          </>
        ) : null}
      </div>
    </Layout>
  );
};

export default Comite;