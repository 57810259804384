import { mdiFileImage, mdiFilePdf } from "@mdi/js";
import { getApiUrl } from "../functions";
import { IComite, IProgramaTrabajo } from "./interfaces";


export const programaTrabajoCepci: IProgramaTrabajo[] = [
  
    {
        title: 'Bases para la Integración del Comité de Ética',
        list: [
              {
                name: 'Bases para la Integración, Organización y Funcionamiento del Comité de Ética y de Prevención de Conflicto de Interés del ISSET',
                updtedAt: '23/04/2024',
                size: '1.44 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/bases-integracion/Bases-Integracion-Organizacion-Funcionamiento-CEPCI-ISSET.pdf`,
                icon: mdiFilePdf,
              },
              
        ],
      },
      {
        title: 'Calendario',
        list: [
              {
                name: 'Calendario de sesiones CEPCI ISSET 2024',
                updtedAt: '05/03/2024',
                size: '20.0 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/calendario/calendario-sesiones.pdf`,
                icon: mdiFilePdf,
              },
        ],
      },
      {
        title: 'Código de Conducta',
        list: [
              {
                name: 'Código de Conducta y de Prevención del Conflicto de Interés de las Personas Servidoras Públicas del Instituto de Seguridad Social del Estado de Tabasco',
                updtedAt: '12/01/2024',
                size: '3.49 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/codigo-conducta/Codigo_Conducta_Prevencion_Conflicto_Interes_Personas_Servidoras_Publicas.pdf`,
                icon: mdiFilePdf,
              },
        ],
      },
      {
        title: 'Marco Jurídico',
        list: [
              {
                name: 'CE-SP-APE',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/CE-SP-APE.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'Código de Ética  de las Personas Servidoras Publicas del Estado de Tabasco.',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/codigo-etica.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'Convenios fundamentales de la OIT',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/convenios-fundamentales.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'Declaración Universal de los Derechos Humanos',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/Declaracion_U_DH.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'Declaracion_U_DH',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/declaracion-derechos-humanos.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'GCC-2021',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/GCC-2021.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'INDICADORES_EVALUAR_CUMPLIMIENTO_CODIGOS_ETICA_CONDUCTA',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/indicadores-cumplimiento-codigo-etica.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'LG-IyF-CE',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/LG-IyF-CE.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'TC-EI-CEyPCI',
                updtedAt: '13/12/2023',
                size: '3,178 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/marco-juridico/TC-EI-CEyPCI.pdf`,
                icon: mdiFilePdf,
              },
        ],
      },
      {
        title: 'Plan Anual de Trabajo',
        list: [
              {
                name: 'Programa Anual de Trabajo 2024 | ISSET',
                updtedAt: '23/04/2024',
                size: '1.46 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/plan-anual/Programa-Anual-Trabajo-2024-ISSET.pdf`,
                icon: mdiFilePdf,
              },
        ],
      },
      {
        title: 'Informe Anual de Actividades (IAA)',
        list: [
              {
                name: 'Informe Anual de Actividades (IAA) 2023',
                updtedAt: '05/03/2024',
                size: '4.196 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/informe-anual-actividades/Informe-Anual-Actividades-2023.pdf`,
                icon: mdiFilePdf,
              },
        ]
      },
      {
        title: 'Resultados',
        list: [],
      },
      {
        title: 'Evidencia Fotografica',             
        list: [
          {
            name: 'Reunión Comité de Ética y de Prevención de Conflicto de Interes ',                      
            file:   `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencias-fotograficas/reunion-comite-etica.jpeg`,
            icon: mdiFileImage,
          },
          {
            name: 'Portada Código de Conducta y de Prevención de Conflicto de Interés de las Personas Servidoras Públicas 2023',                      
            file:   `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencias-fotograficas/Codigo-conducta-2023-bc.jpg`,
            icon: mdiFileImage,
          },
    ],
      },
      {
        title: 'Evidencia Digital',
        list: [
              {
                name: 'Subdirección de Cultura Deporte y Recreación',
                updtedAt: '23/04/2024',
                size: '2.40 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Subdirección de Cultura Deporte y Recreación.pdf`,
                icon: mdiFilePdf,
              },
              {
                name: 'Dirección de Finanzas',
                updtedAt: '23/04/2024',
                size: '504 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Dirección de Finanzas.pdf`,
                icon: mdiFilePdf,
              },                   
              {
                name: 'Dirección de Administración',
                updtedAt: '23/04/2024',
                size: '2.33 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Dirección de Administración.pdf`,
                icon: mdiFilePdf,
              },  
              {
                name: 'Difusión de la Direccion de Prestaciones Socioenómicas',
                updtedAt: '23/04/2024',
                size: '764 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Difusión de la Direccion de Prestaciones Socioenómicas.pdf`,
                icon: mdiFilePdf,
              },  
              {
                name: 'Subdirección de Servicios Asistenciales',
                updtedAt: '23/04/2024',
                size: '1.06 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Subdirección de Servicios Asistenciales.pdf`,
                icon: mdiFilePdf,
              },  
              {
                name: 'Subdirección de Prestaciones Económicas y Pensiones',
                updtedAt: '23/04/2024',
                size: '1.06 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Subdirección de Prestaciones Económicas y Pensiones.pdf`,
                icon: mdiFilePdf,
              },  
              {
                name: 'Direccion de Prestaciones Socioenómicas',
                updtedAt: '23/04/2024',
                size: '764 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Direccion de Prestaciones Socioenómicas.pdf`,
                icon: mdiFilePdf,
              },  
              {
                name: 'Unidad de Apoyo Juridico',
                updtedAt: '23/04/2024',
                size: '616 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Unidad de Apoyo Juridico.pdf`,
                icon: mdiFilePdf,
              },  
              {
                name: 'Unidad de Transparencia ISSET',
                updtedAt: '23/04/2024',
                size: '144 KB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/evidencia-digital/Unidad de Transparencia ISSET.pdf`,
                icon: mdiFilePdf,
              }
        ],
      },
      {
        title: 'Carta Compromiso',
        
        list: [
              {
                name: 'Carta Compromiso de las Personas Servidoras Públicas del ISSET',
                updtedAt: '05/03/2024',
                size: '1.46 MB',
                file: `${getApiUrl('portal')}/static/docs/tu-instituto/organos-colegiados/comite-etica/carta-compromiso/Carta-Compromiso-Personas-Servidoras-Publicas-ISSET.pdf`,
                icon: mdiFilePdf,
              },
        ],
      },
];

export const comites: Partial<IComite>[] = [
//   {
//     name: 'Comité de Premios y Reconocimientos',
//     abreviatura: 'CPR',
//     objetivos: [],
//     funciones: [],
//     estructuras: [],
//     marcoJuridico: '',
//     programaTrabajo: programaTrabajoPremios
//   },
  {
    name: 'COMITÉ DE ÉTICA Y DE PREVENCIÓN DE CONFLICTO DE INTERÉS DE LAS PERSONAS SERVIDORAS PÚBLICAS DEL ISSET',
    abreviatura: 'CEPCI',
    objetivos: [
        'Establecer la forma en que las personas servidoras públicas del Instituto de Seguridad Social del Estado de Tabasco deberán aplicar el conjunto de principios, valores y reglas de integridad que orienten al desempeño de sus funciones y la toma de decisiones, mostrando los compromisos de   nuestra   institución,   así   como   las   actitudes   en   favor   de   la Derechohabiencia y entre los compañeros trabajadores, que fomente una   cultura   de   identidad   institucional,   mediante   pautas   de comportamiento que fortalezcan el deber y responsabilidad social'
      ],
    funciones: [],
    estructuras: [],
    marcoJuridico: '',
    programaTrabajo: programaTrabajoCepci,
    ubicacion: 'Avenida   27   de   febrero   número   930,   colonia   Centro,   C.P.   86000, Villahermosa, Tabasco.',
    contacto: [
        {
            name: 'correo',
            description: 'cepci@isset.gob.mx',
        }
    ],
    miembros: {
      mostrar: true,
      foto: require('../../assets/images/comites/miembros-comite-cepci.jpg'),
      title: 'Miembros del comité de ética y prevención',
      children:[
        {
          title: 'Integrantes',
          miembros: [
            {
              name: "M.A.P. Mónica del Rosario Laguna García",
              description: "Presidente"
            },
            {
              name: "Arq. Miriam Cortes Lorenzo",
              description: "Presidente Suplente"
            },
            {
              name: "M.D. Minerva Ocaña Pérez",
              description: "Secretaria Ejecutiva"
            },
            {
              name: "Mtro. Mario Samuel Hernández Ricardez",
              description: "Secretario Ejecutivo Suplente"
            },
            {
              name: "L.D. Keyrin Aylen Peralta Javier",
              description: "Secretaria Técnica"
            },
            {
              name: "L.A. Kristhel Guadalupe Morales González",
              description: "Secretaria Técnica Suplente"
            },
            {
              name: "Mtra. Natalia Antonieta Laguna García",
              description: "Dirección o Titular de Unidad"
            },
            {
              name: "Dr. Humberto Azuara Forcelledo",
              description: "Dirección o Titular de Unidad Suplente"
            },
            {
              name: "Lic. Isabel Cristina Wall Olivier",
              description: "Subdirección"
            },
            {
              name: "M.D. Azalea Argaiz Gutiérrez",
              description: "Subdirección Suplente"
            },
            {
              name: "L.C.P. Rosa María Castro Frías",
              description: "Jefatura de Departamento"
            },
            {
              name: "Lic. Enrique Álvarez Aguilar",
              description: "Jefatura de Departamento Suplente"
            },
            {
              name: "Tec. Cont. María Conchita Velázquez de la Cruz",
              description: "Jefatura de Área"
            },
            {
              name: "C.Roberto Pardo González",
              description: "Jefatura de Área Suplente"
            },
            {
              name: "Lic. Adrián Cordero Vázquez",
              description: "Profesional Especializado"
            },
            {
              name: "Ing. Mariana del Carmen Aguilar Quiñones",
              description: "Profesional Especializado Suplente"
            },
            {
              name: "Ing. Nati del Carmen Gómez Segovia",
              description: "Jefatura de Proyecto"
            },
            {
              name: "L.S. Sherley del Socorro Reyes Méndez",
              description: "Jefatura de Proyecto Suplente"
            },
            {
              name: "C. Samantha Castillo Escalante",
              description: "Auxiliar Administrativo"
            },
            {
              name: "C. José Víctor Castro Rivera",
              description: "Auxiliar Administrativo Suplente"
            }
          ]
      },
      {
          title: 'Por el Órgano Interno de Control',
          miembros: [
              {
                  name: "L.C.P. Fernando de los Santos Rodríguez",
                  description: "Órgano Interno de Control Propietario"
                },
                {
                  name: "L.C.P. Rosa Luna Torres",
                  description: "Órgano Interno de Control Suplente"
                }
          ]
      },
      {
          title: 'Asesores',
          miembros: [
              {
                  name: "Lic. César Anastasio Pérez Priego Cobián",
                  description: "Titular de la Unidad de Apoyo Jurídico"
                },
                {
                  name: "M.V.Z. Luis Alfonso Preciado Colorado",
                  description: "Subdirector de Recursos Humanos"
                }
          ]
      },
      {
          title: 'Asesores Suplentes',
          miembros: [
            {
              name: "Lic. Rigoberto Chablé Chablé",
              description: "Subdirector de Asuntos Jurídicos"
            },  
            {
              name: "L.C.P. Rebeca Bernal Álvarez",
              description: "Jefa del Departamento de Administración de Personal"
            }               
          ]
      },
      ]
    },
  }
];

