import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTheme, Collapse, ListItem, ListItemText, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

interface IItemProgramaTrabajo {
  programaTrabajo: string,
  setProgramaTrabajo: Dispatch<SetStateAction<string>>,
  text: string,
  children?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
}

const ItemProgramaTrabajo: FunctionComponent<IItemProgramaTrabajo> = ({ programaTrabajo, setProgramaTrabajo, text, children }) => {
  const theme = useTheme();

  const handleCollapse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const value = e.currentTarget.id === programaTrabajo ? '' : e.currentTarget.id;
    setProgramaTrabajo(value);
  }

  return (
    <>
      <ListItem divider button id={text} onClick={handleCollapse}>
        <ListItemText primary={text} />
        {programaTrabajo === text ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={programaTrabajo === text} timeout="auto" unmountOnExit>
        {children ? children : (
          <div style={{ padding: theme.spacing(2) }}>
            <Typography color='error'>No hay información disponible para este apartado.</Typography>
          </div>
        )}
      </Collapse>
    </>
  );
};

export default ItemProgramaTrabajo;